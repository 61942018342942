
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.cms-form {
  ::v-deep .g-field,
  ::v-deep .skeleton {
    &.subject {
      @include bp-lg-laptop {
        grid-column: 1 / 6;
      }
    }
    &.date {
      @include bp-lg-laptop {
        grid-column: 6 / 11;
      }

      grid-column: 1 / 6;
    }
    &.keywords {
      @include bp-lg-laptop {
        grid-column: 1 / 9;
      }
    }
  }

  .preview-button.invalid {
    opacity: 0.75;
  }
  .actions {
    flex-direction: column;
    @include bp-md-tablet {
      flex-direction: row;
    }

    .g-button,
    .skeleton-button {
      @include bp-md-tablet {
        flex-direction: row;
      }
      &:not(:first-child) {
        margin-top: 10px;
        margin-left: 0;
        @include bp-md-tablet {
          margin-top: 0;
          margin-left: 10px;
        }
      }
    }
  }
}
