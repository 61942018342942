
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.news-preview-modal {
  ::v-deep {
    padding: 20px;
    > .content {
      width: 100%;
      max-width: 950px;
      max-height: calc(100vh - 40px);
      padding: 50px 0;
      background-color: white;
    }
  }
  .content {
    width: 80%;
    margin: 0 auto;
    .headline {
      margin: 0;
      font-family: $family-oswald;
      font-size: rem(30);
      font-weight: bold;
      line-height: 30px;
      text-transform: uppercase;
      letter-spacing: em(30, 3);
    }
    .main-image {
      width: 100%;
      max-height: 350px;
      object-fit: cover;
      margin: 30px 0;
    }

    .subheader {
      margin-bottom: 9px;
      font-size: rem(22);
      line-height: 30px;
      color: $gk-darkest-grey;
      letter-spacing: em(22, 0.55);
    }
    .date {
      margin: 30px 0 0;
      color: $gk-grey;
    }

    .body-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      .body-left {
        width: 100%;
        .body {
          margin-top: 1px;
          margin-bottom: 30px;
          white-space: pre-line;
        }
      }

      .body-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        @include bp-sm-phone-landscape {
          justify-content: flex-start;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        img,
        .p-skeleton {
          margin-bottom: 10px;
        }
      }
    }
    .long-text {
      @include bp-custom-min(850) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      .long-text-left {
        @include bp-custom-min(850) {
          width: 60%;
        }
      }

      .long-text-right {
        @include bp-custom-min(850) {
          width: 30%;
        }

        img {
          @include bp-sm-phone-landscape {
            width: 350px;
            height: 250px;
          }

          @include bp-custom-min(850) {
            display: block;
            width: 100%;
            max-width: 309px;
            height: 100%;
          }
        }
      }
    }

    .short-text {
      .long-text-left {
        width: 100%;
      }

      .short-text-right {
        width: 100%;

        img {
          width: 100%;
          height: 100%;

          @include bp-sm-phone-landscape {
            width: 45%;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
